
import {Timestamp} from 'firebase/firestore'
import axios from 'axios';
const URL = 'https://aryoconnect.in/';
// const URL_UAT = 'http://34.31.137.130:80/';
// const URL = "http://localhost:8080/";

export async function getProjectDetails(hash, _db, callBack) {

    const result = await axios.get(URL + 'projectDetails',  { params: { hash: hash, db: _db } });   
    callBack(result.data);
}

export async function getEcomProjectDetails(hash, callBack) {
  const result = await axios.get(URL + 'ecomProjectDetails',  { params: { hash: hash } });   
  callBack(result.data);
}

export async function getLinkCashBack(id, callBack) {
  // const result = await axios.get(URL_UAT + "getOfferLink/?id=" + id);
  // callBack(result.data);
  callBack({'url': 'https://tjzuh.com/g/rb1qie435b503295f347a80d05f527/?subid={gid}', 'name': 'Flipkart'});
}

export async function submitClick(click, callBack) {
  // const result = await axios.post(URL_UAT + 'submitClick', click);
  // callBack(result.data);
  callBack(true);
}

export async function postLead(lead, callBack) {
    const result = await axios.post(URL + 'submitDistLead', lead);
    callBack(result.data);
}

export async function postCustLead(lead, callBack) {
    var result = null;
    if (lead.db === 'DB01') {
      result = await axios.post(URL + 'submitLead', lead);
    }
    else {
      result = await axios.post(URL + 'submitEcomLead', lead);
    }
    callBack(result.data);
}

export async function isUidExist(uid, callback) {
  const result = await axios.get(URL + 'isUidExist', {params: {'uid':uid}})
  callback(result.data);
}

export const _getSubId = (prefix) => {
    return ('T_' + prefix + Timestamp.now().toJSON().seconds + 
        Timestamp.now().toJSON().nanoseconds.toString().substring(0, 3)); 
}


export async function getAgentDetails(ownerId , uid, callBack) {
    try {
      const details = await axios.get(URL + 'getTeamAgentDetails', {
        params: { ownerId : ownerId ,  uid: uid },
      });
      callBack(details.data);
    } catch (error) {
      console.error("Error fetching agent details", error);
    }
  }

  export async function checkPincode(pincode , callBack) {
    const result = await axios.get(URL + 'checkPincode' , {
      params : {
        pincode : pincode ,
      } ,
    });
    callBack(result.data);
  }
  
